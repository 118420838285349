<template>
  <div>
    <b-row class="no-gutters px-3 px-sm-0">
      <b-col md="3" sm="12" lg="5" class="d-flex align-items-center mb-3">
        <h4 class="mr-sm-4 mb-0 header-tablepage">Shop 1</h4>
      </b-col>
    </b-row>
    <div class="no-gutters p-4 bg-white mt-3">
      <div class="row mb-2" v-for="obj of dataObj" :key="obj.name">
        <b-col md="3" sm="6" cols="12" class="text-sm-right">
          <b>{{ obj.name }} : </b>
        </b-col>
        <b-col md="9" sm="6" cols="12">
          {{ obj.value }}
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataObj: [
        {
          name: "Date / Time",
          value: new Date(),
        },
        { name: "Shop Name", value: "" },
        { name: "Shop Type", value: "" },
        { name: "Header", value: "" },
        { name: "Request Parameter", value: "" },
        { name: "Request Method", value: "" },
        { name: "URL", value: "" },
        { name: "Request Status", value: "" },
        { name: "Log", value: "" },
      ],
    };
  },
  mounted() {
    const div = document.querySelector(".breadcrumb-item.active");
    div.classList.contains("active");
    let span = div.getElementsByTagName("span")[0];
    // span.innerHTML('test')
    span.innerHTML = "Shop 1";
    // this.$router.replace({ name: "test" });
  },
};
</script>

<style></style>
